import React, { FC, lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'

import Send from 'src/pages/Send'
import { Div } from './components/ui'

import { Loading } from './components/Loading'

const Components = lazy(() => import(/* webpackChunkName: "Components" */ 'src/pages/Components'))

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Div flexGrow={1}>
            <Div p={['2.2rem', '2.5rem']} flexGrow={1}>
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/" element={<Send />} />
                </Routes>
              </Suspense>
            </Div>
          </Div>
        }
      />
    </Routes>
  )
}

export default AppRoutes
