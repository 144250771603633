import React from 'react'
import HopLogoBlack from 'src/assets/logos/hop-logo-black.svg'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

function SendHeader(props: any) {
  const { styles } = props

  return (
    <div className={styles.header}>
      <Box display="flex" alignItems="center" className={styles.sendSelect}>
        <img className={styles.hopLogo} src={HopLogoBlack} alt="Hop" />

        <Typography variant="h5" className={styles.sendLabel} style={{ marginLeft: '4px' }}>
          Support
        </Typography>
      </Box>
    </div>
  )
}

export default SendHeader
