import React, { FC, useState, useMemo, useEffect, ChangeEvent } from 'react'
import { Button } from 'src/components/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import SendIcon from '@material-ui/icons/Send'
import Box from '@material-ui/core/Box'
import SendAmountSelectorCard from 'src/pages/Send/SendAmountSelectorCard'
import { BigNumber } from 'ethers'
import Network from 'src/models/Network'
import { useWeb3Context } from 'src/contexts/Web3Context'
import { useApp } from 'src/contexts/AppContext'
import {
  findMatchingBridge,
  sanitizeNumericalString,
  toTokenDisplay,
  networkSlugToId,
} from 'src/utils'
import useSendData from 'src/pages/Send/useSendData'
import { ChainSlug } from '@hop-protocol/sdk'
import { amountToBN, formatError } from 'src/utils/format'
import { useSendStyles } from './useSendStyles'
import SendHeader from './SendHeader'
import { Flex } from 'src/components/ui'
import { useSendTransaction } from './useSendTransaction'
import {
  useAssets,
  useFeeConversions,
  useQueryParams,
  useBalance,
  useGnosisSafeTransaction,
} from 'src/hooks'
import { Card, Typography } from '@material-ui/core'
import { LargeTextField } from 'src/components/LargeTextField'
import { toast } from 'react-toastify'

const Send: FC = () => {
  const styles = useSendStyles()
  const {
    networks,
    txConfirm,
    txHistory,
    sdk,
    bridges,
    selectedBridge,
    setSelectedBridge,
    settings,
  } = useApp()
  const { slippageTolerance, deadline } = settings
  const { address, connectedNetworkId } = useWeb3Context()
  const { queryParams, updateQueryParams } = useQueryParams()
  const [fromNetwork, _setFromNetwork] = useState<Network>()
  const [toNetwork, _setToNetwork] = useState<Network>()
  const [fromTokenAmount, setFromTokenAmount] = useState<string>()
  const [toTokenAmount, setToTokenAmount] = useState<string>()
  const [warning, setWarning] = useState<any>(null)
  const [error, setError] = useState<string | null | undefined>(null)
  const [customRecipient, setCustomRecipient] = useState<string>('')
  // Reset error message when fromNetwork/toNetwork changes
  useEffect(() => {
    if (warning) {
      setWarning('')
    }
    if (error) {
      setError('')
    }
  }, [fromNetwork, toNetwork])

  // Set fromNetwork and toNetwork using query params
  useEffect(() => {
    const _fromNetwork = networks.find(network => network.slug === queryParams.sourceNetwork)
    _setFromNetwork(_fromNetwork)

    const _toNetwork = networks.find(network => network.slug === queryParams.destNetwork)

    if (_fromNetwork?.name === _toNetwork?.name) {
      // Leave destination network empty
      return
    }

    _setToNetwork(_toNetwork)
  }, [queryParams, networks])

  // use the values saved to localStorage for default network selection (if they exist)
  useEffect(() => {
    const fromNetworkString = localStorage.getItem('fromNetwork')
    const savedFromNetwork = fromNetworkString ? JSON.parse(fromNetworkString) : ''

    const toNetworkString = localStorage.getItem('toNetwork')
    const savedToNetwork = toNetworkString ? JSON.parse(toNetworkString) : ''

    if (savedFromNetwork) {
      setFromNetwork(savedFromNetwork)
    } else if (!queryParams.sourceNetwork) {
      setFromNetwork(
        networks.find(network => network.chainId === networkSlugToId(ChainSlug.Ethereum))
      )
    }

    if (savedToNetwork) {
      setToNetwork(savedToNetwork)
    }
  }, [])

  // update localStorage on network change for persistent field values
  useEffect(() => {
    if (fromNetwork) {
      localStorage.setItem('fromNetwork', JSON.stringify(fromNetwork))
    }

    if (toNetwork) {
      localStorage.setItem('toNetwork', JSON.stringify(toNetwork))
    }
  }, [fromNetwork, toNetwork])

  // update fromNetwork to be the connectedNetwork on load and change
  useEffect(() => {
    if (connectedNetworkId) {
      setFromNetwork(networks.find(network => network.chainId === connectedNetworkId))
    }

    // ensure fromNetwork is not the same as toNetwork
    if (queryParams.sourceNetwork && queryParams.sourceNetwork === queryParams.destNetwork) {
      setToNetwork(undefined)
    }
  }, [connectedNetworkId])

  useEffect(() => {
    if (queryParams.amount && !Number.isNaN(Number(queryParams.amount))) {
      setFromTokenAmount(queryParams.amount as string)
      updateQueryParams({
        amount: undefined,
      })
    }
  }, [queryParams])

  // Get assets
  const { unsupportedAsset, sourceToken, destToken, placeholderToken } = useAssets(
    selectedBridge,
    fromNetwork,
    toNetwork
  )

  // Get token balances for both networks
  const { balance: fromBalance, loading: loadingFromBalance } = useBalance(sourceToken, address)
  const { balance: toBalance, loading: loadingToBalance } = useBalance(destToken, address)

  // Set fromToken -> BN
  const fromTokenAmountBN = useMemo<BigNumber | undefined>(() => {
    if (fromTokenAmount && sourceToken) {
      return amountToBN(fromTokenAmount, sourceToken.decimals)
    }
  }, [sourceToken, fromTokenAmount])

  // Use send data for tx
  const {
    amountOut,
    rate,
    priceImpact,
    amountOutMin,
    intermediaryAmountOutMin,
    adjustedBonderFee,
    adjustedDestinationTxFee,
    totalFee,
    requiredLiquidity,
    relayFeeEth,
    loading: loadingSendData,
    estimatedReceived,
    error: sendDataError,
  } = useSendData(sourceToken, slippageTolerance, fromNetwork, toNetwork, fromTokenAmountBN)

  // Set toAmount
  useEffect(() => {
    if (!destToken) {
      setToTokenAmount('')
      return
    }

    let amount: any
    if (amountOut) {
      amount = toTokenDisplay(amountOut, destToken.decimals)
    }
    setToTokenAmount(amount)
  }, [destToken, amountOut])

  // Convert fees to displayed values
  const { estimatedReceivedDisplay } = useFeeConversions({
    destinationTxFee: adjustedDestinationTxFee,
    bonderFee: adjustedBonderFee,
    estimatedReceived,
    destToken,
    relayFee: relayFeeEth,
  })

  // ==============================================================================================
  // Send tokens
  // ==============================================================================================

  const { tx, setTx, send, sending, setIsGnosisSafeWallet } = useSendTransaction({
    amountOutMin,
    customRecipient,
    deadline,
    totalFee,
    fromNetwork,
    fromTokenAmount,
    intermediaryAmountOutMin,
    sdk,
    setError,
    sourceToken,
    toNetwork,
    txConfirm,
    txHistory,
    estimatedReceived: estimatedReceivedDisplay,
  })

  useEffect(() => {
    if (tx) {
      // clear from token input field
      setFromTokenAmount('')
    }
  }, [tx])

  const { gnosisEnabled, gnosisSafeWarning, isCorrectSignerNetwork } = useGnosisSafeTransaction(
    tx,
    customRecipient,
    fromNetwork,
    toNetwork
  )

  useEffect(() => {
    setIsGnosisSafeWallet(gnosisEnabled)
  }, [gnosisEnabled])

  // ==============================================================================================
  // User actions
  // - Bridge / Network selection
  // - Custom recipient input
  // ==============================================================================================

  // Change the bridge if user selects different token to send
  const handleBridgeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const tokenSymbol = event.target.value as string
    const bridge = findMatchingBridge(bridges, tokenSymbol)
    if (bridge) {
      setSelectedBridge(bridge)
    }
  }

  // Set fromNetwork
  const setFromNetwork = (network: Network | undefined) => {
    updateQueryParams({
      sourceNetwork: network?.slug ?? '',
    })
    _setFromNetwork(network)
  }

  // Set toNetwork
  const setToNetwork = (network: Network | undefined) => {
    updateQueryParams({
      destNetwork: network?.slug ?? '',
    })
    _setToNetwork(network)
  }

  // Switch the fromNetwork <--> toNetwork
  const handleSwitchDirection = () => {
    setToTokenAmount('')
    setFromNetwork(toNetwork)
    setToNetwork(fromNetwork)
  }

  // Change the fromNetwork
  const handleFromNetworkChange = (network: Network | undefined) => {
    if (network?.slug === toNetwork?.slug) {
      handleSwitchDirection()
    } else {
      setFromNetwork(network)
    }
  }

  // Change the toNetwork
  const handleToNetworkChange = (network: Network | undefined) => {
    if (network?.slug === fromNetwork?.slug) {
      handleSwitchDirection()
    } else {
      setToNetwork(network)
    }
  }

  const [txHash, setTxHash] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const i = setTimeout(() => {
      setShow(submitting)
    }, 1000)

    return () => clearTimeout(i)
  }, [submitting])

  return (
    <Flex column alignCenter>
      <SendHeader
        styles={styles}
        bridges={bridges}
        selectedBridge={selectedBridge}
        handleBridgeChange={handleBridgeChange}
      />

      {/*
      <SendAmountSelectorCard
        value={fromTokenAmount}
        token={sourceToken ?? placeholderToken}
        label={'From'}
        onChange={value => {
          if (!value) {
            setFromTokenAmount('')
            setToTokenAmount('')
            return
          }

          const amountIn = sanitizeNumericalString(value)
          setFromTokenAmount(amountIn)
        }}
        selectedNetwork={fromNetwork}
        networkOptions={networks}
        onNetworkChange={handleFromNetworkChange}
        balance={fromBalance}
        loadingBalance={loadingFromBalance}
        deadline={deadline}
        toNetwork={toNetwork}
        fromNetwork={fromNetwork}
        setWarning={setWarning}
        maxButtonFixedAmountToSubtract={sourceToken?.symbol === 'ETH' ? relayFeeEth : 0}
      />

      <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem"></Box>

      <SendAmountSelectorCard
        value={toTokenAmount}
        token={destToken ?? placeholderToken}
        label={'To'}
        selectedNetwork={toNetwork}
        networkOptions={networks}
        onNetworkChange={handleToNetworkChange}
        balance={toBalance}
        loadingBalance={loadingToBalance}
        loadingValue={loadingSendData}
        disableInput
      />
*/}
      <Card className={styles.customRecipient}>
        <Typography
          variant="subtitle2"
          className={styles.customRecipientLabel}
          color="textSecondary"
        >
          Transaction hash
        </Typography>
        <LargeTextField
          leftAlign
          fullWidth
          value={txHash}
          onChange={e => setTxHash(e.target.value.trim())}
          placeholder="Enter transaction hash (e.g. 0x123...)"
          smallFontSize
        />
      </Card>

      <Card className={styles.customRecipient}>
        <Typography
          variant="subtitle2"
          className={styles.customRecipientLabel}
          color="textSecondary"
        >
          Email
        </Typography>
        <LargeTextField
          leftAlign
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value.trim())}
          placeholder="Enter your email address..."
          smallFontSize
        />
      </Card>

      <Card className={styles.customRecipient}>
        <Typography
          variant="subtitle2"
          className={styles.customRecipientLabel}
          color="textSecondary"
        >
          Description
        </Typography>
        <LargeTextField
          leftAlign
          fullWidth
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Issue detail..."
          smallFontSize
        />
      </Card>

      {submitting && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <div style={{ position: 'relative' }}>
            {show && (
              <div
                style={{
                  position: 'absolute',
                  top: '2px',
                  bottom: '2px',
                  left: '2px',
                  width: '60%',
                  background: '#f9f9f9',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  padding: '12px',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    display: 'block',
                    shapeRendering: 'auto',
                  }}
                  width="24px"
                  height="24px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#689de2"
                    strokeWidth="8"
                    r="30"
                    strokeDasharray="141.37166941154067 49.12388980384689"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    />
                  </circle>
                </svg>
                <div>I'm not a robot</div>
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                top: '2px',
                bottom: '2px',
                right: '2px',
                width: '40%',
              }}
            ></div>

            <ReCAPTCHA
              sitekey="6LfetCUpAAAAAL4YE1g7O5zv6aJncmJMu3GrGZ9I"
              onChange={e => {
                //
              }}
            />
          </div>
          {show && (
            <div
              style={{
                maxWidth: '480px',
                textAlign: 'center',
                marginBottom: '1rem',
                marginTop: '12px',
              }}
            >
              Cannot contact reCAPTCHA. Check your network connection and try again.
            </div>
          )}
        </div>
      )}

      <Button
        style={{ width: '100%', maxWidth: '480px', marginTop: '1rem' }}
        className={styles.button}
        startIcon={
          submitting ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                margin: 'auto',
                display: 'block',
                shapeRendering: 'auto',
              }}
              width="36px"
              height="36px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                r="32"
                strokeWidth="8"
                stroke="#c24be8"
                strokeDasharray="50.26548245743669 50.26548245743669"
                fill="none"
                strokeLinecap="round"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;1"
                  values="0 50 50;360 50 50"
                />
              </circle>
            </svg>
          ) : (
            <SendIcon />
          )
        }
        onClick={() => {
          if (!txHash) {
            toast.error('Please input your transaction hash', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            return
          }

          setSubmitting(true)
          // const functionThatReturnPromise = new Promise((_resolve, reject) =>
          //   setTimeout(() => {
          //     reject(new Error('reject'))
          //     setSubmitting(false)
          //   }, 1000)
          // )
          // toast.promise(functionThatReturnPromise, {
          //   pending: 'Submiting',
          //   success: '',
          //   error:
          //     'Sorry, something went wrong. Please check your network connection and try again',
          // })
        }}
        disabled={submitting}
        large
        fullWidth
        highlighted
      >
        {submitting ? 'Submitting...' : 'Submit'}
      </Button>
    </Flex>
  )
}

export default Send
